
import { BSwitch } from 'buetify/lib/components';
import BButton from 'buetify/lib/components/button/BButton';
import BField from 'buetify/lib/components/form/field';
import BBox from 'buetify/lib/components/layout/box/BBox';
import BLoading from 'buetify/lib/components/loading/BLoading';
import BMessage from 'buetify/lib/components/message/BMessage';
import { defineComponent, shallowRef } from 'vue';

export default defineComponent({
	name: 'template-loading-example',
	components: {
		BLoading,
		BButton,
		BSwitch,
		BMessage,
		BField,
		BBox
	},
	setup() {
		const isLoading = shallowRef(false);
		const isFullscreen = shallowRef(true);

		function openLoading() {
			isLoading.value = true;
		}

		return {
			isLoading,
			isFullscreen,
			openLoading
		};
	}
});
